import { Link } from "gatsby";
import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import { Title } from "@components/Typography";
import { PostThumbnailLink } from "@components/PostThumbnail";
import BlogPostCategories from "@components/BlogPostCategories";

const BlogPost = ({
  postTitle,
  postDate,
  featuredImage,
  slug,
  fragen,
  projekte,
  twofiftyDinge,
  news,
  downloads,
}) => {
  const formattedDate = postDate
    ? new Date(Date.parse(postDate)).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : null;
  return (
    <Div
      css={css`
        margin-bottom: 32px;
        width: 100%;

        @media (min-width: 768px) {
          max-width: 50%;
          padding: 0px 16px;
        }

        @media (min-width: 1200px) {
          max-width: 33.33%; //test
          padding: 0px 16px;
        }
      `}
    >
      <PostThumbnailLink featuredImage={featuredImage} url={`/blog/${slug}`} />
      <BlogPostCategories
        fragen={fragen}
        news={news}
        downloads={downloads}
        projekte={projekte}
        dinge={twofiftyDinge}
      />
      <Link
        to={`/blog/${slug}`}
        css={css`
          margin-bottom: 30px;
          &:hover {
            opacity: 0.75;
            cursor: pointer;
          }
          @media (min-width: 768px) {
            min-height: 80px;
          }
        `}
      >
        <Title tag="h2" size="xs" css={css``}>
          {postTitle}
        </Title>
        <p
          css={css`
            font-size: 12px;
            opacity: 0.5;
          `}
        >
          {formattedDate}
        </p>
      </Link>
    </Div>
  );
};

export default BlogPost;
