import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
import SEO from "@components/seo";
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Section, Div, Container } from "@components/Markup";
import { Title } from "@components/Typography";
import BlogPost from "@components/BlogPost";
import { split, forEach, remove, orderBy, slice, find } from "lodash";
import Pagination from "@components/Pagination";

const blogCategories = [
  {
    datoKey: "fragen",
    slug: "113-fragen",
    displayName: "113 Fragen",
  },
  {
    datoKey: "downloads",
    slug: "downloads",
    displayName: "Downloads",
  },
  {
    datoKey: "twofiftyDinge",
    slug: "250-dinge",
    displayName: "250 Dinge",
  },
  {
    datoKey: "news",
    slug: "news",
    displayName: "News",
  },
  {
    datoKey: "projekte",
    slug: "projekte",
    displayName: "Projekte",
  },
];

const orderPostsByDate = edges => {
  return orderBy(
    orderBy(
      edges.map(edge => edge.node),
      ["postDate"],
      ["desc"]
    ),
    { downloads: true },
    ["desc"]
  );
};

const getCategoryAndPageNumber = pathname => {
  let subpaths = split(pathname, "/");
  remove(subpaths, str => str.length === 0);
  const pageNumber = subpaths.length > 2 ? subpaths[subpaths.length - 1] : 1;
  let cat = subpaths[1];
  if (cat === "page") {
    cat = "all";
  }

  return { pageNumber, cat };
};

const POSTS_PER_PAGE = 9;

const BlogPage = ({ data }) => {
  const [postsByPage, setPostsByPage] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [category, setCategory] = useState(null);

  const blogPosts = orderPostsByDate(data.posts.edges);
  const location = useLocation();

  useEffect(() => {
    const { pageNumber, cat } = getCategoryAndPageNumber(location.pathname);
    let blogPostsByCategory = [];
    forEach(blogPosts, post => {
      if (cat && cat !== "all" && find(blogCategories, { slug: cat })) {
        const categoryDatoKey = find(blogCategories, { slug: cat })["datoKey"];
          if (post[categoryDatoKey] === true) {
            blogPostsByCategory.push(post);
          }
      } else {
        blogPostsByCategory.push(post);
      }
    });
    // ako na stranica sa 2 posta, se nujdaem ot the second slice
    // t.e. 0,1 ; 2, 3; 4, 5
    setCategory(cat);
    setCurrentPage(pageNumber);

    const _pageCount = Math.ceil(blogPostsByCategory.length / POSTS_PER_PAGE);
    setPageCount(_pageCount > 1 ? _pageCount : null);

    const startIndex = (pageNumber - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;
    const slicedPosts = slice(blogPostsByCategory, startIndex, endIndex);
    setPostsByPage(slicedPosts);
  }, [location.pathname]);

  const displayName =
    (!category || category === "all" || !find(blogCategories, { slug: category }))
      ? ""
      : find(blogCategories, { slug: category })["displayName"];
  const seoTitle = `Hawlik Gerginski Architekten | Impulse ${displayName}`;
  const seoDescription = `Add SEO description`;

  return (
    <React.Fragment>
      <SEO title={seoTitle} description={seoDescription} />
      <Section pt="lg" pb="sm">
        <Container>
          <Title size="xl" uppercase mb="md">
            {displayName}
          </Title>
          <Div
            flex
            css={css`
              flex-wrap: wrap;

              @media (min-width: 768px) {
                margin: 0px -16px;
              }
            `}
          >
            {postsByPage.map((post, index) => {
              return (
                <BlogPost
                  key={`post--${index}`}
                  id={`post--${index}`}
                  {...post}
                />
              );
            })}
          </Div>
        </Container>
        {pageCount && (
          <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            baseUrl={category !== "all" ? `/blog/${category}` : `/blog`}
          />
        )}
      </Section>
    </React.Fragment>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogPostsPageQuery {
    posts: allDatoCmsBlogPost(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          postTitle
          postDate
          slug
          excerpt
          fragen
          news
          downloads
          twofiftyDinge
          projekte
          featuredImage {
            fluid {
              src
              srcSet
              aspectRatio
            }
            focalPoint {
              x
              y
            }
          }
        }
      }
    }
  }
`;
